import axios from 'axios';


let currentController = null;
export default {
  // getPrimaryEquipmentsWithPagination(pageNumber, pageSize, nameFilter, energyObjectIdFilter, duOrganizationName, dvOrganizationName,
  //                                    baseVoltageFilter, primaryEquipmentTypeIdFilter, skipEoObject, isLocalNsiFilter, isRuFilter, hideLines, sortField, sortDesc) {
  //   return axios.post('/api/PrimaryEquipments/GetPrimaryEquipmentsWithPagination', {
  //     pageNumber,
  //     pageSize,
  //     nameFilter,
  //     energyObjectIdFilter: energyObjectIdFilter.length > 0 ? energyObjectIdFilter.join('!;!') : '',
  //     duOrganizationName: duOrganizationName.length > 0 ? duOrganizationName.join('!;!') : '',
  //     dvOrganizationName: dvOrganizationName.length > 0 ? dvOrganizationName.join('!;!') : '',
  //     baseVoltageFilter: baseVoltageFilter.length > 0 ? baseVoltageFilter.join('!;!') : '',
  //     primaryEquipmentTypeIdFilter: primaryEquipmentTypeIdFilter.length > 0 ? primaryEquipmentTypeIdFilter.join('!;!') : '',
  //     skipEoObject,
  //     isLocalNsiFilter,
  //     isRuFilter,
  //     hideLines,
  //     sortField,
  //     sortDesc
  //   });
  // },
  async getPrimaryEquipmentsWithPagination(params) {  
    // Отменяем предыдущий запрос, если он существует
    if (currentController) {
      currentController.abort();
    }
    currentController = new AbortController();
    const { signal } = currentController;
    const response = await axios.post('/api/PrimaryEquipments/GetPrimaryEquipmentsWithPagination/', params, { signal });
    currentController = null;
    return response;
  }, 
  getLinesWithPagination(params) {
    params.energyObjectIdFilter = params.energyObjectIdFilter.length > 0 ? params.energyObjectIdFilter.join('!;!') : '';
    params.duOrganizationName = params.duOrganizationName.length > 0 ? params.duOrganizationName.join('!;!') : '';
    params.dvOrganizationName = params.dvOrganizationName.length > 0 ? params.dvOrganizationName.join('!;!') : '';
    params.baseVoltageFilter = params.baseVoltageFilter.length > 0 ? params.baseVoltageFilter.join('!;!') : '';
    return axios.post('/api/PrimaryEquipments/GetLinesWithPagination', params);
      // {
      //   pageNumber,
      //   pageSize,
      //   nameFilter,
      //   energyObjectIdFilter: energyObjectIdFilter.length > 0 ? energyObjectIdFilter.join('!;!') : '',
      //   duOrganizationName: duOrganizationName.length > 0 ? duOrganizationName.join('!;!') : '',
      //   dvOrganizationName: dvOrganizationName.length > 0 ? dvOrganizationName.join('!;!') : '',
      //   baseVoltageFilter: baseVoltageFilter.length > 0 ? baseVoltageFilter.join('!;!') : '',
      //   skipEoObject,
      //   isLocalNsiFilter,
      //   isRuFilter,
      //   sortField,
      //   sortDesc
      // });
  },
  getPrimaryEquipmentItem(id) {
    return axios.get('/api/PrimaryEquipments/GetPrimaryEquipmentItem', {
      params: {
        id
      }
    });
  },
  addPrimaryEquipment: (primaryEquipment) => axios.post('/api/PrimaryEquipments', primaryEquipment),
  updatePrimaryEquipment: (primaryEquipment) => axios.put(`/api/PrimaryEquipments/${primaryEquipment.id}`, primaryEquipment),
  deletePrimaryEquipment: (id) => axios.delete(`/api/PrimaryEquipments/${id}`),
  mergePrimaryEquipments(mainId, secondaryIds) {
    return axios.post('/api/PrimaryEquipments/Merge', { mainId, secondaryIds });
  },
};

